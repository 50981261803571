import { isArray, isObject } from './assertions'

type ConditionalClassNameObject = Record<string, boolean>
export function cx(
  classNames: string | Array<string | undefined> | ConditionalClassNameObject
): string {
  if (isArray(classNames)) {
    return (classNames as Array<string>)
      .filter((className) => {
        return !!className
      })
      .join(' ')
  }

  if (isObject(classNames)) {
    const className = Object.keys(classNames)
      .filter((key) => {
        return (classNames as ConditionalClassNameObject)[key]
      })
      .join(' ')
    return className
  }

  return `${classNames}` as string
}

// used when a node needs to take multiple refs.
// currently used in select-experimental.tsx
export function mergeRefs(...refs: any) {
  return (node: any) => {
    for (const ref of refs) {
      if (ref) {
        ref.current = node
      }
    }
  }
}
