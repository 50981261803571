import { Flex } from 'components/layout'
import { motion } from 'framer-motion'
import React from 'react'
import Loader from '../loader/loader'
import { StyledButton } from './button.styles'
import { ButtonProps } from './button.types'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, prepend, append, isLoading, ...buttonProps }, ref) => {
    // replace this with append and prepend components
    function ButtonWrapper() {
      return (
        <Flex align="center" justify="center" gutterX={2} stretchx>
          {prepend && <>{prepend}</>}
          {children}
          {append && <>{append}</>}
        </Flex>
      )
    }
    return (
      <StyledButton {...buttonProps} ref={ref}>
        <motion.div
          initial={{ opacity: 0, y: '100%', position: 'absolute' }}
          animate={{
            opacity: isLoading ? 1 : 0,
            y: isLoading ? 0 : '100%',
            position: 'absolute',
            transition: {
              duration: 0.2,
            },
          }}
        >
          <Loader color="#E1E1E1" size="sm" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: '-100%' }}
          animate={{
            opacity: isLoading ? 0 : 1,
            y: isLoading ? '-100%' : 0,
            transition: {
              duration: 0.2,
            },
          }}
        >
          <ButtonWrapper />
        </motion.div>
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

export default Button
