import { styled } from 'stitches/stitches.config'

export const Box = styled('div', {
  variants: {
    stretchx: { true: { width: '100%' } },
    stretchy: { true: { height: '100%' } },
  },
})

export default Box
