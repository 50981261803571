import axios from 'axios'
import { OperationResult } from 'urql'
import { isArray, isUndefinedOrNull } from './assertions'
import { getToken } from './auth'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export function hashCode(text: any) {
  const str = String(text)
  let hash = 0
  let char
  if (str.trim().length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    // Convert to 32bit integer
    hash &= hash
  }

  return Math.abs(hash)
}

export function extractGraphqlErrors(
  response: OperationResult,
  operationName: string
): string | null | any {
  if (response.error?.message) {
    return response.error.message.replace('[GraphQL]', '')
  }

  if (response.error || !response.data) {
    return 'Something went wrong! Please try again'
  }

  const operationResult = response.data[operationName]

  if (!isUndefinedOrNull(operationResult?.errors)) {
    if (
      isArray(operationResult?.errors) &&
      operationResult?.errors?.length > 0
    ) {
      return (
        operationResult?.errors[0].message ??
        'Something went wrong! Please try again'
      )
    }

    return null
  }

  return null
}

export function id() {
  return Math.random().toString(36)
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function parsePhoneNumber(phoneNumber: string) {
  const [dialingCode, phoneNumberWithoutDialingCode] = phoneNumber.split(' ')
  return {
    dialingCode,
    phoneNumber: phoneNumberWithoutDialingCode,
  }
}

export function formatMoney(value: string | number = 0, symbol = ' ') {
  let number
  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('Please pass in a string or number to this function')
  }
  number = typeof value === 'string' ? Number(value) : value

  let options = {
    style: 'currency',
    currency: 'USD',
  }

  if (number > 1000000) {
    options = {
      ...options,
      notation: 'compact',
      maximumFractionDigits: 2,
    } as any
  }

  number = number
    .toLocaleString(undefined, options)
    .replace('$', '')
    .replace('US', '')
  return `${symbol} ${number}`
}

export const truncateText = (text: string, length: number) => {
  if (text.length > length) {
    return text.substring(0, length) + '...'
  }
  return text
}

export async function downloadFileFromUrl(
  url: string,
  as: string,
  format: 'csv' | 'pdf' = 'csv',
  useToken?: boolean
) {
  const token = getToken()
  const api = useToken ? `${url}?token=${token}` : url

  const a = document.createElement('a')
  await axios
    .get(api, {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((response) => {
      a.download = `${as}.${format}`
      const blob = new Blob([response.data], { type: `application/${format}` })
      a.href = URL.createObjectURL(blob)
      a.click()
      URL.revokeObjectURL(a.href)
    })
}

export const RUNNING_ON_PRODUCTION =
  process.env.REACT_APP_DEPLOYMENT_ENV === 'production'

export function getApiUrl() {
  if (RUNNING_ON_PRODUCTION) {
    return process.env.REACT_APP_PRODUCTION_API as string
  }

  return process.env.REACT_APP_STAGING_API as string
}

export function getSocketUrl() {
  if (RUNNING_ON_PRODUCTION) {
    return process.env.REACT_APP_WEBSOCKET_PRODUCTION_URL as string
  }

  return process.env.REACT_APP_WEBSOCKET_STAGING_URL as string
}

export function getStripePublishableKey() {
  if (RUNNING_ON_PRODUCTION) {
    return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
  }

  return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
}

export function getMonoKey() {
  if (RUNNING_ON_PRODUCTION) {
    return process.env.REACT_APP_MONO_KEY as string
  }

  return process.env.REACT_APP_MONO_KEY as string
}

export function extractMonthAndYear(date: Date) {
  return {
    month: months[date.getMonth()],
    year: date.getFullYear(),
  }
}

export function greeting() {
  const time = new Date().getHours()
  if (time < 12) {
    return 'Morning'
  } else if (time < 18) {
    return 'Afternoon'
  } else {
    return 'Evening'
  }
}

export function formatLocaleDate(date: Date | string) {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-')
}

export function snakeCaseToWord(str: string) {
  return str
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ')
}

export function sliceName(name: string, limit: number) {
  return name?.length > limit ? name?.slice(0, limit) + '...' : name
}

export function isValidEmail(value: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value)
}

export const isNumeric = (value: any): boolean => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return false;
  }

  // Check if the string or number contains only numeric characters or valid numeric format
  return /^[0-9]+$/.test(value.toString());
};
