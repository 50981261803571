import { keyframes, styled } from 'stitches/stitches.config'

const loadingAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
})

export const StyledLoader = styled('div', {
  background: 'transparent',
  borderRadius: '50%',
  borderStyle: 'solid',
  animation: `${loadingAnimation} .45s infinite linear`,
  flexShrink: 0,
  willChange: 'transform',

  defaultvariants: { size: 'md' },

  variants: {
    size: {
      xxs: {
        height: '1.3rem',
        width: '1.3rem',
        borderWidth: '.5rem',
      },

      xs: {
        height: '1.6rem',
        width: '1.6rem',
        borderWidth: '.2rem',
      },

      sm: {
        height: '2.4rem',
        width: '2.4rem',
        borderWidth: '.3rem',
      },

      md: {
        height: '3.2rem',
        width: '3.2rem',
        borderWidth: '.3rem',
      },

      lg: {
        height: '4rem',
        width: '4rem',
        borderWidth: '.3rem',
      },
    },
  },
})
