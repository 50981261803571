import { HiCheck, HiChevronDown, HiOutlineSearch, HiX } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { StyledInputWrapper } from 'components/input/input.styles'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import Button from 'components/button/button'
import Input from 'components/input/input'

export const StyledSelectContainer = styled('div', {
  position: 'relative',
  cursor: 'pointer',
})

export const StyledCaret = styled(HiChevronDown, {
  color: '$secondary',
  transition: 'all 0.3s linear',
})

export const StyledCheck = styled(HiCheck, {
  color: '$secondary',
})

export const StyledX = styled(HiX, {
  color: '$secondary',
})

export const StyledSearch = styled(HiOutlineSearch, {
  color: '$secondary',
})

export const Root = styled(Flex, { height: '100%' })

export const Value = styled(Flex, {
  fontFamily: '$medium',
  fontSize: 'inherit',
  color: '$primary',
  width: '100%',
})

export const SelectTrigger = styled('div', StyledInputWrapper, {
  display: 'flex',
  alignItems: 'center',
  minWidth: 50,
  fontSize: 'inherit',
  backgroundColor: '$white !important',
  border: '0.1rem solid $border',
  boxShadow: '$tiny !important',
  cursor: 'pointer',
  // py: '1.4rem ',

  '&:focus-within': {
    borderColor: '$highlightBg !important',
    backgroundColor: '$highlightBg !important',
    boxShadow: 'none',
  },

  '.chevron': { color: '$gray10' },
  '.placeholder': { fontSize: 'inherit', width: '100%' },
})

export const SelectItem = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  px: '1.6rem',
  py: '0.8rem',
  minHeight: '3.6rem',
  fontFamily: '$medium',
  fontSize: '$2',
  cursor: 'pointer',
  userSelect: 'none',
  color: '$primary',
  backgroundColor: '$white',

  variants: {
    isActive: { true: { background: '$background', color: '$primary' } },
    isSelected: { true: { background: '$highlightBg', color: '$highlight' } },
  },

  compoundVariants: [
    {
      isActive: true,
      isSelected: true,
      css: { background: '$highlightBg' },
    },
  ],
})

export const SelectContent = styled(Box, {
  background: '$white',
  borderRadius: 8,
  boxShadow: '0 0.8rem 2rem rgb(0 0 0 / 8%)',
  border: '0.1rem solid $gray1',
  minWidth: 140,
  position: 'fixed',
  px: 0,
  py: 0,
  zIndex: 1,
  pointerEvents: 'auto',
})

export const Viewport = styled(Box, {
  maxHeight: '36rem',
  overflowY: 'scroll',
})

export const SelectTag = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: '$semibold',
  letterSpacing: 0.3,
  gap: '$1',
  background: '$border',
  color: '$primary',
  borderRadius: 4,
  height: '2.5rem',
  px: '0.8rem',
})

export const StyledAppPreContainer = styled(Flex, {
  flexShrink: 0,

  variants: {
    type: {
      append: {
        marginLeft: '0.96rem',
      },

      prepend: {
        marginRight: '0.96rem',
      },
    },
  },
})

export const StyledSelectFooter = styled(Flex, {
  padding: '1.2rem 0.8rem 0.8rem 1.6rem',
  position: 'sticky',
  bottom: 0,
  borderTop: '0.1rem solid $border',
})

export const StyledClearButton = styled(Button, {
  padding: '0 !important',
  width: 'fit-content !important',
  height: 'fit-content !important',
  boxShadow: 'none !important',
})

export const StyledSearchFilter = styled(Input, {
  '& .input-wrapper': {
    borderRadius: '0 !important',
    border: 'none !important',
    borderBottom: '0.1rem solid $border !important',
  },
})
