import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import React from 'react'
import { CSS } from 'stitches/stitches.config'

type IProps = React.ComponentProps<typeof Text> & {
  children: React.ReactNode
  className?: string
  css?: CSS
}

export const InputError = React.forwardRef<HTMLParagraphElement, IProps>(
  ({ children, className, css }, ref) => {
    return (
      <Flex
        ref={ref}
        css={css}
        className={'input-error' + (className ? ` ${className}` : '')}
      >
        <Text color="$error" variant="caption" size="xxs">
          {children}
        </Text>
      </Flex>
    )
  }
)

InputError.displayName = 'InputError'

export default InputError
