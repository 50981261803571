import React, { ReactNode, Ref } from 'react'
import { cx } from 'utils/dom'
import { AddOn, Wrapper } from './append-prepend-wrapper.styles'
import { IProps } from './append-prepend-wrapper.types'

const AppendPrependWrapper = React.forwardRef<
  HTMLDivElement,
  IProps & React.ComponentProps<typeof Wrapper>
>(({ append, prepend, children, className, align }, ref) => {
  return (
    <Wrapper
      align={align}
      ref={ref as Ref<HTMLDivElement>}
      className={cx([className, 'append-prepend-wrapper'])}
    >
      {prepend && <AddOn className="add-on prepend">{prepend}</AddOn>}
      {children as ReactNode}
      {append && <AddOn className="add-on append">{append}</AddOn>}
    </Wrapper>
  )
})

AppendPrependWrapper.displayName = 'AppendPrependWrapper'

export default AppendPrependWrapper
