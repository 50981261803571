import type * as Stitches from '@stitches/react'
import { createStitches } from '@stitches/react'
export type { VariantProps } from '@stitches/react'

const baseFontStack = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
export const { styled, css, globalCss, keyframes, theme, createTheme, config } =
  createStitches({
    prefix: 'acntr',
    theme: {
      colors: {
        blue: '#4E5A2D',
        purple: '#CDC5FF',
        deepPurple: '#9585F4',
        primary: '#575F6A',
        secondary: '#848C96',
        background: '#F8F6F7',
        highlightBg: '#F8F8F8',
        // highlightBg: '#E2FF70',
        highlight: '#363C26',
        inputBg: '#F5F7F8',
        disabledBg: '#F8F8F8',
        disabled: '#E1E1E1',
        errorBg: '#FBEEEF',
        error: '#F15656',
        warning: '#DDAE09',
        successBg: '#EEFFE6',
        success: '#59A160',
        white: '#FFFFFF',
        black: '#000000',
        border: '#EEF2F4',
        onboardingBg: '#E6F2F9',
        gray: '#ABB3B9',
        grayDark2: '#1C1C1C',
        settingsFooterbg: '#FCFCFC',
        backdropBg: 'rgba(134, 146, 157, 0.25)', //'rgba(0,0,0,0.45)',
      },
      fonts: {
        regular: `accounteer regular, ${baseFontStack}`,
        primary: `accounteer semi, ${baseFontStack}`,
        bold: `accounteer bold, ${baseFontStack}`,
        space: `Space Grotesk, ${baseFontStack}`,
      },
      space: {
        1: '.4rem',
        2: '.8rem',
        3: '1.6rem',
        4: '2rem',
        5: '2.4rem',
        6: '3.2rem',
        7: '4.8rem',
        8: '6.4rem',
        9: '8.0rem',
      },
      sizes: {
        1: '.4rem',
        2: '.8rem',
        3: '1.6rem',
        4: '2.0rem',
        5: '2.4rem',
        6: '3.2rem',
        7: '4.8rem',
        8: '6.4rem',
        9: '8.0rem',
      },
      fontSizes: {
        1: '1.2rem',
        2: '1.4rem',
        3: '1.6rem',
        4: '1.8rem',
        5: '2.2rem',
        6: '2.8rem',
        7: '4rem',
      },
      lineHeights: {
        1: '1.6rem',
        2: '2rem',
        3: '2.4rem',
        4: '3.2rem',
        5: '4rem',
        6: '5.2rem',
      },
      radii: {
        1: '.4rem',
        2: '.6rem',
        3: '.8rem',
        4: '1.2rem',
        round: '50%',
      },
      zIndices: {
        1: '100',
        2: '200',
        3: '300',
        4: '400',
        max: '999',
      },
      shadows: {
        tiny: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        subtle: '0px 1px 2px rgba(0, 0, 0, 0.04)',
        deep: '0px 20px 48px rgba(0, 0, 0, 0.14)',
        normal: '0rem 1.2rem 3.2rem rgba(0, 0, 0, 10%)',
        focusRing: '0 0 0 4px #e5e5e5',
        focusRingError: `0px 0px 0px 1px #FE7474, 0px 0px 0px 4px rgba(254, 116, 116, 0.25)`,
        focusRingValid: `0px 0px 0px 1px #4E5A2D, 0px 0px 0px 4px rgba(78, 90, 45, 0.25)`,
      },
    },
    utils: {
      p: (value: Stitches.PropertyValue<'padding'>) => ({
        padding: value,
      }),
      pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
        paddingTop: value,
      }),
      pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
        paddingRight: value,
      }),
      pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
        paddingLeft: value,
      }),
      px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
        paddingTop: value,
        paddingBottom: value,
      }),

      m: (value: Stitches.PropertyValue<'margin'>) => ({ margin: value }),
      mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
        marginTop: value,
      }),
      mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
        marginRight: value,
      }),
      mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
        marginLeft: value,
      }),
      mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<'marginTop'>) => ({
        marginTop: value,
        marginBottom: value,
      }),
    },
  })

export type CSS = Stitches.CSS<typeof config>

export const globalStyles = globalCss({
  body: {
    color: '$primary',
    fontFamily: '$primary',
  },
  '[role=button]': {
    cursor: 'pointer',
    userSelect: 'none',
  },
  button: {
    cursor: 'pointer',
    '&:focus': { outline: 'none' },
  },
})
