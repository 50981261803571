/* eslint-disable react/prop-types */
import React from 'react'
import { styled } from 'stitches/stitches.config'

const StyledLabel = styled('label', {
  display: 'block',
  color: '$primary',
  marginBottom: '.8rem',
  fontFamily: '$bold',
  fontSize: '1.4rem',

  '.asterik': {
    marginLeft: '.6rem',
    color: '$error',
  },
})

export type InputLabelProps = React.ComponentProps<typeof StyledLabel> & {
  required?: boolean
}

export const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ required, children, htmlFor, css, className }, ref) => {
    return (
      <StyledLabel
        className={'input-label' + (className ? ` ${className}` : '')}
        css={css}
        htmlFor={htmlFor}
        ref={ref}
      >
        {children}
        {required && <span className="asterik">*</span>}
      </StyledLabel>
    )
  }
)

InputLabel.displayName = 'InputLabel'

export default InputLabel
