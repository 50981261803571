import { Route, Routes } from 'react-router-dom'
import { globalStyles } from 'stitches/stitches.config'
import AtlasAi from 'pages/atlas-ai/atlas-ai'

function App() {
  globalStyles()

  return (
    <>
      <Routes>
        {/* <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/reports/:id" element={<ReportsPreview />} />
        <Route path="/verify-email/:id" element={<VerifyEmail />} />
        <Route path="/register" element={<Register />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} /> */}
        <Route path={'/'} element={<AtlasAi />}></Route>
      </Routes>
    </>
  )
}

export default App
