import React from 'react'
import { styled } from 'stitches/stitches.config'
import Box from './box'

export const StyledRow = styled(Box, {
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(12, minmax(min-content, 1fr))',

  defaultvariants: {
    gutter: '$1',
  },

  variants: {
    align: {
      start: { alignItems: 'start' },
      center: { alignItems: 'center' },
      end: { alignItems: 'end' },
      stretch: { alignItems: 'stretch' },
      baseline: { alignItems: 'baseline' },
    },

    justify: {
      start: { justifyContent: 'start' },
      center: { justifyContent: 'center' },
      end: { justifyContent: 'end' },
      between: { justifyContent: 'space-between' },
    },

    gutter: {
      1: { gap: '$1' },
      2: { gap: '$2' },
      3: { gap: '$3' },
      4: { gap: '$4' },
      5: { gap: '$5' },
      6: { gap: '$6' },
      7: { gap: '$7' },
      8: { gap: '$8' },
      9: { gap: '$9' },
    },

    gutterX: {
      1: { columnGap: '$1' },
      2: { columnGap: '$2' },
      3: { columnGap: '$3' },
      4: { columnGap: '$4' },
      5: { columnGap: '$5' },
      6: { columnGap: '$6' },
      7: { columnGap: '$7' },
      8: { columnGap: '$8' },
      9: { columnGap: '$9' },
    },

    gutterY: {
      1: { rowGap: '$1' },
      2: { rowGap: '$2' },
      3: { rowGap: '$3' },
      4: { rowGap: '$4' },
      5: { rowGap: '$5' },
      6: { rowGap: '$6' },
      7: { rowGap: '$7' },
      8: { rowGap: '$8' },
      9: { rowGap: '$9' },
    },
  },
})

type RowProps = React.ComponentProps<typeof StyledRow> & {
  children: React.ReactNode
}

const Row = React.forwardRef<HTMLDivElement, RowProps>(
  ({ children, ...rowProps }, ref) => {
    return (
      <StyledRow role="row" {...rowProps} ref={ref}>
        {children}
      </StyledRow>
    )
  }
)

Row.displayName = 'Row'

export default Row
