import { VariantProps } from '@stitches/react'
import { InputError, InputLabel } from 'components/input'
import { Box } from 'components/layout'
import React from 'react'
import ReactSelect, {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  MenuListProps,
  MenuProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SingleValueProps,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu'
import { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable'
import { CSS } from 'stitches/stitches.config'
import {
  CustomClearIndicator,
  CustomControl,
  CustomDropdownIndicator,
  CustomMenu,
  CustomMenuList,
  CustomMenuPortal,
  CustomMultiValue,
  CustomOption,
  CustomPlaceholder,
  CustomSingleValue,
  StyledControl,
} from './custom-components'

export type SelectSingleValue = string | number
export type SelectMultiValue = Array<SelectSingleValue>
export type SelectValue = SelectMultiValue | SelectSingleValue

export type TypeAheadSelectProps = {
  error?: string
  label?: string
  append?: React.ReactNode
  prepend?: React.ReactNode
  customControl?: React.ComponentType<ControlProps>
  customDropdownIndicator?: React.ComponentType<DropdownIndicatorProps>
  customClearIndicator?: React.ComponentType<ClearIndicatorProps>
  customPlaceholder?: React.ComponentType<PlaceholderProps>
  customMenu?: React.ComponentType<MenuProps>
  customOption?: React.ComponentType<OptionProps>
  customSingleValue?: React.ComponentType<SingleValueProps>
  customMultiValue?: React.ComponentType<MultiValueProps>
  customMenuList?: React.ComponentType<MenuListProps>
  customMenuPortal?: React.ComponentType<MenuPortalProps<any, any, any>>
} & VariantProps<typeof StyledControl> &
  Omit<Props, 'onChange' | 'value' | 'size'> & {
    css?: CSS
    onChange?(value: SelectValue): void
    value?: SelectValue | null
    defaultValue?: SelectValue | null
  } & CreatableAdditionalProps<any, any>

const TypeAheadSelect = React.forwardRef<HTMLDivElement, TypeAheadSelectProps>(
  (
    {
      label,
      options = [],
      labelKey = 'label',
      valueKey = 'value',
      css,
      defaultValue,
      onChange,
      value,
      id,
      required,
      isClearable,
      isCreatable,
      isMulti,
      error,
      hideErrorMessage,
      customControl = CustomControl,
      customDropdownIndicator = CustomDropdownIndicator,
      customClearIndicator = CustomClearIndicator,
      customPlaceholder = CustomPlaceholder,
      customMenu = CustomMenu,
      customOption = CustomOption,
      customSingleValue = CustomSingleValue,
      customMultiValue = CustomMultiValue,
      customMenuList = CustomMenuList,
      customMenuPortal = CustomMenuPortal,
      ...props
    },
    ref
  ) => {
    function _onChange(newValue: any) {
      let value = newValue[valueKey]
      if (isMulti) {
        value = newValue.map((value: any) => value[valueKey] ?? value['value'])
      }
      onChange?.(value)
    }

    function findOptionByValue(value: any) {
      if (options.length > 0 && (options[0] as any)['options']) {
        const optionCategory = options.find((option) =>
          ((option as any)['options'] as any[]).find(
            (subOption) => subOption[valueKey] === value
          )
        )

        if (optionCategory && (optionCategory as any)['options']) {
          const option = ((optionCategory as any)['options'] as any[]).find(
            (subOption) => subOption[valueKey] === value
          )

          return option
        }

        return ''
      }

      return options.find((option: any) => option[valueKey] === value)
    }

    function getValue() {
      if (isMulti) {
        return (value as SelectMultiValue)?.map((value: any) => {
          return findOptionByValue(value)
        })
      }
      return findOptionByValue(value)
    }

    function getDefaultValue() {
      if (isMulti) {
        return (defaultValue as SelectMultiValue)?.map((value: any) =>
          findOptionByValue(value)
        )
      }
      return findOptionByValue(defaultValue)
    }

    const _props: Props = {
      hasError: !!error,
      menuPortalTarget: document.body,
      classNamePrefix: 'accounteer-type-ahead',
      valueKey: valueKey,
      labelKey: labelKey,
      getOptionValue: (option: any) => option[valueKey] ?? option['value'],
      getOptionLabel: (option: any) => option[labelKey] ?? option['label'],
      isMulti,
      options: options,
      value: getValue(),
      // value: _value,
      defaultValue: getDefaultValue(),
      styles: {
        control: () => ({
          height: '100%',
          width: '100%',
        }),
        option: () => ({}),
        dropdownIndicator: () => ({}),
        indicatorSeparator: () => ({ display: 'none' }),
        multiValue: (base) => ({ ...base }),
        menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
        valueContainer: (base) => ({ ...base, padding: 0 }),
      },
      isClearable: isClearable,
      components: {
        Control: customControl,
        DropdownIndicator: customDropdownIndicator,
        ClearIndicator: customClearIndicator,
        Placeholder: customPlaceholder,
        Menu: customMenu,
        Option: customOption,
        SingleValue: customSingleValue,
        MultiValue: customMultiValue,
        MenuList: customMenuList,
        MenuPortal: customMenuPortal,
      },
      onChange: _onChange,
      ...props,
    }

    return (
      <Box stretchx stretchy css={css} ref={ref}>
        {label && (
          <InputLabel htmlFor={id} required={required}>
            {label}
          </InputLabel>
        )}

        {isCreatable ? (
          <CreatableSelect {..._props} />
        ) : (
          <ReactSelect aria-label={label} {..._props} />
        )}
        {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 4 }}>{error}</InputError>
        )}
      </Box>
    )
  }
)

TypeAheadSelect.displayName = 'TypeAheadSelect'

export default TypeAheadSelect
