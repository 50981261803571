import AppendPrependWrapper from 'components/append-prepend-wrapper/append-prepend-wrapper'
import Flex from 'components/layout/flex'
import React from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
import InputError from './components/input-error'
import InputLabel from './components/input-label'
import {
  StyledContainer,
  StyledInput,
  StyledInputWrapper,
} from './input.styles'
import { InputProps } from './input.types'

export const Input = React.forwardRef<HTMLDivElement, InputProps>(
  (props, ref) => {
    const {
      appearance,
      append,
      prepend,
      size,
      label,
      error,
      css,
      id,
      type,
      required,
      disabled,
      wrapperStyle,
      hideErrorMessage,
      className,
      name,
      inputRef,
      ...inputProps
    } = props

    const [inputType, setInputType] = React.useState(type)

    function toggleInputType() {
      if (inputType === 'password') {
        setInputType('text')
        return
      }
      setInputType('password')
    }

    return (
      <StyledContainer
        className={'input' + (className ? ` ${className}` : '')}
        ref={ref}
        css={css}
        direction="column"
      >
        {label && (
          <InputLabel required={required} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        <Flex direction="column" gutterX="3" css={{ flexGrow: 1 }}>
          <StyledInputWrapper
            className="input-wrapper"
            appearance={appearance}
            size={size}
            state={error ? 'invalid' : 'none'}
            isDisabled={disabled}
            css={wrapperStyle}
          >
            <AppendPrependWrapper
              append={
                type === 'password' ? (
                  <button
                    onClick={toggleInputType}
                    className="password-toggle"
                    type="button"
                  >
                    {inputType === 'password' ? 'show' : 'hide'}
                  </button>
                ) : (
                  append
                )
              }
              prepend={prepend}
            >
              <StyledInput
                disabled={disabled}
                className="input-el"
                type={inputType}
                id={name}
                name={name}
                ref={inputRef}
                {...inputProps}
              />
            </AppendPrependWrapper>

            {error && <RiErrorWarningFill color="#F15656" />}
          </StyledInputWrapper>
        </Flex>
        {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 6 }}>{error}</InputError>
        )}
      </StyledContainer>
    )
  }
)

Input.displayName = 'Input'

export default Input
