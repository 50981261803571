import React from 'react'
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider,
} from 'urql'
import { getToken, setToken } from 'utils/auth'
import { getApiUrl } from 'utils/helpers'

const client = createClient({
  url: getApiUrl(),
  fetchOptions: () => {
    const token = getToken()
    if (token) {
      return {
        headers: { Authorization: `Bearer ${token}` },
      }
    }

    return {}
  },
  exchanges: [
    dedupExchange,
    cacheExchange,
    errorExchange({
      onError: (error) => {
        const { pathname } = window.location
        localStorage.setItem('lastPath', pathname)
        const isAuthError = error.graphQLErrors.some((e) => {
          return (
            e.message === 'Signature has expired' ||
            e.message === 'You need to be authenticated to perform this action'
          )
        })
        // process.env.NODE_ENV === 'development' && console.log(error)
        if (isAuthError || error.message === '[Network] Failed to fetch') {
          setToken(null)
          window.location.href = '/login'
        }
        // 422
        if (error.response.status === 422) {
          window.location.href = '/login'
        }
      },
    }),
    fetchExchange,
  ],
})

export default function UrqlProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return <Provider value={client}>{children}</Provider>
}
