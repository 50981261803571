import { styled } from 'stitches/stitches.config'

export const StyledButton = styled('button', {
  appearance: 'none',
  border: 'none',
  background: 'none',
  userSelect: 'none',
  outline: 'none',
  overflow: 'hidden',

  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  transition: 'all .3s',
  cursor: 'pointer',
  fontFamily: '$bold',
  whiteSpace: 'nowrap',
  boxShadow: '0 .1rem .2rem rgb(0 0 0 / 10%)',
  py: '2.35rem',

  '&:disabled': {
    cursor: 'not-allowed',
    boxShadow: 'none',
    opacity: 0.5,
  },

  '&:focus': { boxShadow: '$focusRing' },

  defaultVariants: {
    appearance: 'primary',
    size: 'md',
  },

  variants: {
    appearance: {
      primary: {
        background: '$blue',
        color: '$white',
        border: '0.1rem solid $blue',
      },
      black: {
        background: '$black',
        color: '$white',
        border: '0.1rem solid $black',
      },
      secondary: {
        background: '$white',
        color: '$primary',
        border: '.1rem solid $border',
        '.loader': { borderTopColor: '$primary', borderRightColor: '$primary' },
      },

      ghost: {
        background: 'transparent',
        color: '$primary',
        boxShadow: 'none !important',
        padding: '0 !important',
        height: 'fit-content !important',
      },
      danger: { background: '$error', color: '$white' },
    },

    block: {
      true: { width: '100%' },
    },

    color: {
      ghost: {
        background: 'transparent',
        color: '$blue',
      },
    },

    size: {
      xs: {
        height: '2.4rem',
        fontSize: '1.2rem',
        borderRadius: '.4rem',
        px: '1.2rem',
        py: 0,
      },

      sm: {
        height: '3.2rem',
        fontSize: '1.2rem',
        borderRadius: '.4rem',
        px: '1.2rem',
        py: 0,
      },

      md: {
        height: '4rem',
        fontSize: '1.4rem',
        borderRadius: '.4rem',
        px: '1.6rem',
        py: 0,
      },

      lg: {
        height: '4.4rem',
        fontSize: '1.4rem',
        borderRadius: '.4rem',
        px: '1.8rem',
      },

      xl: {
        height: '4.8rem',
        fontSize: '1.4rem',
        borderRadius: '.4rem',
        py: 0,
        px: '1.8rem',
      },

      xxl: {
        height: '5.6rem',
        fontSize: '1.6rem',
        borderRadius: '.8rem',
        px: '1.8rem',
      },
    },

    fullWidth: {
      true: {
        width: '100% !important',
      },
    },
  },
})
