import { styled } from 'stitches/stitches.config'

export const StyledText = styled('span', {
  variants: {
    size: {
      xxs: {
        fontSize: '$1',
        lineHeight: '$1',
      },

      xs: {
        fontSize: '$2',
        lineHeight: '$2',
      },

      sm: {
        fontSize: '$3',
        lineHeight: '$2',
      },

      md: {
        fontSize: '$4',
        lineHeight: '$3',
      },

      lg: {
        fontSize: '$5',
        lineHeight: '$4',
      },

      xl: {
        fontSize: '$6',
        lineHeight: '$5',
      },

      xxl: {
        fontSize: '$7',
        lineHeight: '$6',
      },
    },

    disabled: {
      true: {
        opacity: '0.3',
        cursor: 'not-allowed',
      },
    },
  },
})
