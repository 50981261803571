import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import UrqlProvider from 'urql/urql-provider'
import App from './App'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <UrqlProvider>
      <Router>
        <App />
      </Router>
    </UrqlProvider>
  </React.StrictMode>
)

reportWebVitals()
