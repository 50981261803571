import React from 'react'
import { styled } from 'stitches/stitches.config'
import Flex from './flex'

const StyledCol = styled(Flex, {
  variants: {
    span: {
      1: { gridColumn: 'span 1' },
      2: { gridColumn: 'span 2' },
      3: { gridColumn: 'span 3' },
      4: { gridColumn: 'span 4' },
      5: { gridColumn: 'span 5' },
      6: { gridColumn: 'span 6' },
      7: { gridColumn: 'span 7' },
      8: { gridColumn: 'span 8' },
      9: { gridColumn: 'span 9' },
      10: { gridColumn: 'span 10' },
      11: { gridColumn: 'span 11' },
      12: { gridColumn: 'span 12' },
    },
  },
})

type ColProps = React.ComponentProps<typeof StyledCol> & {
  children: React.ReactNode
}

export const Col = React.forwardRef<HTMLDivElement, ColProps>(
  ({ children, ...colProps }, ref) => {
    return (
      <StyledCol direction="column" role="gridcell" {...colProps} ref={ref}>
        {children}
      </StyledCol>
    )
  }
)

Col.displayName = 'Col'

export default Col
