import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const autoFillSelectors =
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-internal-autofill-selected'

export const StyledContainer = styled(Flex, {
  height: '100%',
  width: '100%',
})

export const StyledInputWrapper = styled(Flex, {
  borderRadius: '$1',
  // width: '100%',
  flexGrow: 1,
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  py: '.35rem',
  px: '1rem',
  alignItems: 'center',
  gap: '$2',

  '.input-el': {
    border: 'none',
    background: 'transparent',
    appearance: 'none',
    fontFamily: '$primary',
    color: '$primary',
    fontSize: 'inherit',
    width: '100%',
    height: '100%',
    padding: 0,
    outline: 'none',

    '&[data-textArea]': {
      py: '1rem',
      resize: 'none',
      minHeight: '8.1rem',
    },

    '&::selection': {
      backgroundColor: '#2d62aca6',
      color: 'white',
    },
  },

  [autoFillSelectors]: {
    '&::selection': {
      backgroundColor: '#2d62aca6',
      WebkitTextFillColor: 'white',
    },
  },

  '.password-toggle': {
    height: 'inherit',
    appearance: 'none',
    background: 'none',
    border: 'none',
    fontSize: 'inherit',
    fontFamily: '$medium',
    color: '$secondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:focus': { color: '$blue' },
  },

  defaultVariants: {
    appearance: 'outline',
    size: 'md',
  },

  variants: {
    size: {
      xs: {
        minHeight: '2.4rem',
        fontSize: '.8rem',
        borderRadius: '.4rem',

        '.input-el': {
          minHeight: '2rem',
        },
      },

      sm: {
        minHeight: '3.2rem',
        fontSize: '1.4rem',
        borderRadius: '.4rem',

        '.input-el': {
          minHeight: '2.8rem',
        },
      },

      md: {
        minHeight: '4rem',
        fontSize: '1.4rem',
        borderRadius: '.4rem',

        '.input-el': {
          height: '3.6rem',
        },
      },

      lg: {
        minHeight: '4.4rem',
        fontSize: '1.6rem',
        borderRadius: '.4rem',

        '.input-el': {
          minHeight: '4rem',
        },
      },

      xl: {
        minHeight: '6rem',
        fontSize: '1.6rem',
        borderRadius: '.8rem',

        '.input-el': {
          minHeight: '5.6rem',
        },
      },
    },

    appearance: {
      outline: {
        background: '$inputBg',
        borderColor: '$inputBg',

        '&:focus-within': {
          boxShadow: '$focusRingValid',
          background: '$white',
          borderColor: '$secondary',
        },
      },
      secondary: {
        background: '$white',
        border: '.1rem solid $border',
        boxShadow: '$subtle',

        '&:focus-within': {
          boxShadow: '$focusRingValid',
          background: '$white',
        },
      },
    },

    state: {
      valid: {
        borderColor: '$blue !important',
        background: '$white !important',
        boxShadow: '$focusRingValid',
        '&:focus-within': { boxShadow: '$focusRingValid' },
      },

      invalid: {
        borderColor: '$error !important',
        background: '$white !important',
        boxShadow: '$focusRingError',
        '&:focus-within': { boxShadow: '$focusRingError' },
      },

      none: {
        // borderColor: 'inherit',
      },
    },

    isDisabled: {
      true: {
        background: '$disabledBg !important',
        border: '.1rem solid $disabled',

        '.input-el': {
          color: '$disabled !important',
          '&::placeholder': { color: '$disabled !important' },
        },
      },
    },
  },
})

export const StyledInput = styled('input', {
  '&::placeholder': { color: '$secondary' },
})

export const StyledTextArea = styled('textarea', {
  '&::placeholder': { color: '$secondary' },
})
