export const STORAGE_KEY = 'atlas_token'

const Cookies = {
  get(key: string) {
    return localStorage.getItem(key)
  },
  set(key: string, value: string) {
    return localStorage.setItem(key, value)
  },
  remove(key: string) {
    return localStorage.removeItem(key)
  },
}

export function getToken(): string | null {
  return Cookies.get(STORAGE_KEY)
}

export function setToken(token: string | null) {
  if (token) {
    Cookies.set(STORAGE_KEY, token)
    return true
  } else {
    Cookies.remove(STORAGE_KEY)
    return false
  }
}
