export const AIresponse = [
  {
    id: 1,
    name: 'Centrum AI',
    message:
      'I found 2,000 suppliers that match your search for electric bikes in the USA. What would you like to do next?',
    actions: [
      'Narrow down by budget',
      'Filter by supplier certifications',
      'See suppliers with the fastest shipping times',
      'Contact top-rated suppliers',
    ],
  },
  {
    id: 2,
    name: 'Centrum AI',
    message:
      'Perfect! Let’s find suppliers that fit your budget. Could you let me know your maximum budget for this purchase?',
    actions: [
      'Less than $1,000',
      '$1,000 - $5,000',
      '$5,000 - $10,000',
      'More than $10,000',
    ],
  },
  {
    id: 3,
    name: 'Centrum AI',
    message:
      'Thanks! I’ve filtered the suppliers to show those within your budget range of $5,000 - $10,000. Now, would you like to:',
    actions: [
      'View the top 10 suppliers',
      'Learn more about the product specifications',
      'Understand shipping and import costs',
      'Request quotes from multiple suppliers',
    ],
  },
  {
    id: 4,
    name: 'Centrum AI',
    message:
      'Great! I will reach out to several suppliers for you. Please provide a bit more information:  <br/> <br/> 1. Desired quantity, <br/> <br/> 2. Specific requirements or customizations, <br/> <br/>3. Preferred shipping method',
  },
  {
    id: 5,
    name: 'Centrum AI',
    message:
      "Here's what the RFQ message I'm sending looks like:<br/><br/><strong>Subject:</strong> Inquiry for Electric Bikes<br/><br/><strong>Message Body:</strong><br/>Hello Guangzhou E-Bike Manufacturing Ltd.,<br/><br/>I am interested in purchasing electric bikes from your company. Could you please provide me with a quotation for the following specifications?<br/><br/>- Model: City Commuter 2000<br/>- Quantity: 500 units<br/>- Customization: Custom branding on frame, blue color scheme<br/>- Preferred Shipping: Sea freight to Los Angeles port<br/><br/>Looking forward to your prompt response.<br/><br/>Best regards,<br/>John Smith<br/>GreenRide Distributors",
    actions: ['Approve this RFQ message', 'Edit this RFQ message'],
  },
  {
    id: 6,
    name: 'Centrum AI',
    message:
      'Perfect! I’ve sent your inquiry to 10 suppliers. You should receive responses within 24-48 hours. In the meantime, would you like to:',
  },
  {
    id: 7,
    name: 'Centrum AI',
    message:
      'Perfect! I’ve sent your inquiry to 10 suppliers. You should receive responses within 24-48 hours. In the meantime, would you like to:',
  },
  {
    id: 8,
    name: 'Centrum AI',
    message:
      'A supplier has responded with a favorable quote! Would you like me to create a Purchase Order (PO) based on this quote?',
    actions: ['Yes, create the PO', 'No, let me review the quote first'],
  },
  {
    id: 9,
    name: 'Centrum AI',
    message:
      'I’m drafting the Purchase Order now. Please provide the following details to complete it: <br/> <br/>1. Billing address <br/> <br/>2. Payment terms <br/> <br/>3. Additional instructions or notes',
  },
  {
    id: 10,
    name: 'Centrum AI',
    message:
      'Thank you! Here is a preview of your Purchase Order:<br/><br/><strong>PO Number:</strong> 00123<br/><strong>Supplier:</strong> Guangzhou E-Bike Manufacturing Ltd.<br/><strong>Product:</strong> City Commuter 2000<br/><strong>Quantity:</strong> 500 units<br/><strong>Customization:</strong> Custom branding on frame, blue color scheme<br/><strong>Shipping:</strong> Sea freight to Los Angeles port<br/><strong>Billing Address:</strong> 123 Greenway Blvd, Suite 400, New York, NY 10001<br/><strong>Payment Terms:</strong> 30 days net<br/><br/>Would you like to make any changes before sending it?',
    actions: ['Approve this PO', 'Edit this PO'],
  },
  {
    id: 11,
    name: 'Centrum AI',
    message:
      'The Purchase Order has been sent to Guangzhou E-Bike Manufacturing Ltd. You will receive a confirmation once they acknowledge receipt. Is there anything else you would like to do today?',
    actions: ['Track the PO status', 'Download the PO'],
  },
  {
    id: 12,
    name: 'Centrum AI',
    message:
      'It looks like you’ve uploaded an invoice. Here are the details I’ve extracted from the invoice:<br/><br/><ul><li><strong>Supplier Name:</strong> Guangzhou E-Bike Manufacturing Ltd.</li><li><strong>Invoice Number:</strong> INV-5678</li><li><strong>Total Amount Due:</strong> $25,000</li><li><strong>Due Date:</strong> August 31, 2024</li><li><strong>Itemized List:</strong><ul><li>Electric Bikes (Model: City Commuter 2000) - 500 units</li><li>Custom Branding Service - 500 units</li></ul></li></ul><br/>Could you please confirm if these details are correct?',
    actions: [
      'Yes, the details are correct',
      'No, I need to edit some details',
    ],
  },
  {
    id: 13,
    name: 'Centrum AI',
    message:
      'Thank you for confirming! Now, how would you like to proceed with the payment for this invoice? Here are the available options:',
    actions: ['Bank Transfer', 'Credit Card', 'PayPal'],
  },
  {
    id: 14,
    name: 'Centrum AI',
    message:
      'Please provide the bank account details where the payment should be sent. This should include:<br/><br/><ol><li>Bank Name</li><li>Account Number</li><li>SWIFT Code</li><li>Beneficiary Name</li></ol>',
  },
  {
    id: 15,
    name: 'Centrum AI',
    message:
      'Thank you! I’ve prepared the payment details for a bank transfer. Would you like to schedule the payment now or set a reminder to make the payment later?',
    actions: ['Schedule Payment Now', 'Set Reminder'],
  },
  {
    id: 16,
    name: 'Centrum AI',
    message:
      'Please confirm the date you would like the payment to be processed. The due date on the invoice is August 31, 2024.',
    actions: ['Today', 'Choose another date'],
  },
  {
    id: 17,
    name: 'Centrum AI',
    message:
      'Payment of $25,000 to Guangzhou E-Bike Manufacturing Ltd. has been scheduled for today. You will receive a confirmation once the payment is processed. Is there anything else you need help with?',
    actions: [
      'Track Payment Status',
      'Upload Another Invoice',
      'No, I’m done for now',
    ],
  },
  {
    id: 18,
    name: 'Centrum AI',
    message:
      'Okay, have a great day! If you need further assistance, feel free to reach out anytime.',
  },
]

export type AIResponseType = {
  id: number
  name: string
  message: string
  actions?: string[]
}
