/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from 'components/layout'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  StyledCaret,
  StyledClearButton,
  Value,
} from 'components/select/select.styles'
import Text from 'components/text'
import { HiCheck, HiChevronDown, HiX } from 'react-icons/hi'
import {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  components,
} from 'react-select'
import {
  MenuListProps,
  MenuPortalProps,
} from 'react-select/dist/declarations/src/components/Menu'
import { styled } from 'stitches/stitches.config'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    hasError?: boolean
    required?: boolean
    data?: []
    labelKey?: string
    valueKey?: string
    filterOutSelected?: boolean
    isCreatable?: boolean
    isClearable?: boolean
    defaultOpen?: boolean
    hideErrorMessage?: boolean
    appearance?: any
    size?: any
    menuFooter?: (props?: any) => React.ReactNode
    menuHeader?: (props?: any) => React.ReactNode
    renderValue?: (value?: any) => React.ReactNode
    renderOption?: (option?: any, meta?: any) => React.ReactNode
  }
}

export const StyledControl = styled(components.Control, SelectTrigger, {
  py: 0,
})

const StyledDropdownIndicator = styled(
  components.DropdownIndicator,
  StyledCaret,
  { pt: 5 }
)

const StyledClearIndicator = styled(
  components.ClearIndicator,
  StyledClearButton
)

const StyledMenu = styled(components.Menu, {
  border: '0.1rem solid $white',
  backgroundColor: '$white',
  borderRadius: 8,
})

const StyledMenuList = styled(components.MenuList, SelectContent)

const StyledMenuPortal = styled(components.MenuPortal, {})

const StyledOption = styled(components.Option, SelectItem, {
  variants: {
    isDisabled: { true: { color: '$background' } },
    isFocused: { true: { background: '$background', color: '$primary' } },
    isSelected: { true: { background: '$highlightBg', color: '$highlight' } },
  },

  compoundVariants: [
    {
      isSelected: true,
      isFocused: true,
      css: { background: '$highlightBg', color: '$highlight' },
    },
  ],
})

const StyledSingleValue = styled(components.SingleValue, Value)

const StyledMultiValue = styled(components.MultiValue)

export const CustomControl = ({ children, ...props }: ControlProps) => {
  return <StyledControl {...props}>{children}</StyledControl>
}
export const CustomMenuList = (props: MenuListProps) => {
  return <StyledMenuList {...props} />
}

export const CustomMenuPortal = (props: MenuPortalProps<any, any, any>) => {
  return <StyledMenuPortal {...props} />
}

export const CustomDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <StyledDropdownIndicator {...props}>
      <HiChevronDown size="1.9rem" />
    </StyledDropdownIndicator>
  )
}

export const CustomClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <StyledClearIndicator {...props}>
      <HiX size={16} />
    </StyledClearIndicator>
  )
}

export const CustomPlaceholder = ({ children, ...props }: PlaceholderProps) => {
  return (
    <components.Placeholder {...props}>
      <Text color="LightGray">{children}</Text>
    </components.Placeholder>
  )
}

export const CustomMenu = ({ children, ...props }: MenuProps) => {
  const { menuFooter, menuHeader } = props.selectProps
  return (
    <StyledMenu {...props}>
      {menuHeader?.(props)}
      {children}
      {menuFooter?.(props)}
    </StyledMenu>
  )
}

export const CustomOption = ({ children, ...props }: OptionProps) => {
  const { renderOption } = props.selectProps

  return (
    <StyledOption {...props}>
      <Flex align={'center'} stretchx justify="between">
        {renderOption ? renderOption?.(props.data, props) : <>{children}</>}
        {props.isSelected && <HiCheck size={16} className="check" />}
      </Flex>
    </StyledOption>
  )
}
export const CustomMultiValue = ({ children, ...props }: MultiValueProps) => {
  return (
    <StyledMultiValue {...props}>
      <Text>{children}</Text>
    </StyledMultiValue>
  )
}

export const CustomSingleValue = ({ children, ...props }: SingleValueProps) => {
  const { renderValue } = props.selectProps

  return (
    <StyledSingleValue {...props}>
      {renderValue ? renderValue?.(props.data) : <Text>{children}</Text>}
    </StyledSingleValue>
  )
}
