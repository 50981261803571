import { styled } from 'stitches/stitches.config'

export const StyleContent = styled('div', {
  background: '#C2C2C2',
  width: '100vw',
  height: '100vh',
  display: 'grid',
  placeContent: 'center',
  fontFamily: 'Inter, sans-serif',
  color: 'black',
  overflow: 'hidden',

  position: 'relative',

  'ul, ol': {
    px: '$3',
  },

  '.box': {
    background: '#EDEEED',
    width: '60rem',
    height: '75rem',
    borderRadius: '2.5rem',
    padding: '1rem 2rem',
    position: 'relative',
    overflow: 'hidden',
  },

  '.top': {
    pt: '1rem',
    height: '5rem',
    img: {
      height: '2rem',
      borderRadius: '50rem',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
  },

  '.content': {
    height: '65rem',
    my: '2rem',
    overflow: 'scroll',
    scrollBehavior: 'smooth',
    // backgroundColor: 'red',
  },

  '.chat': {
    '.atlas': {
      img: {
        height: '8rem',
        animation: `rotate360 10s linear infinite`,
      },
      '.welcome-text': {
        color: '#8DA39D',
        textAlign: 'center',
        width: '70%',
        marginInline: 'auto',
        lineHeight: '140%',
        marginBottom: '1.5rem',
      },
    },
    '.message-wrapper': {
      marginTop: '2rem',
    },
    '.message': {
      background: 'white',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
      borderRadius: '1rem',
      padding: '1rem',
      maxWidth: '35rem',
      fontSize: '1.3rem',

      lineHeight: '150%',
      letterSpacing: '110%',
      mt: '1rem',
    },
    h6: {
      color: '#8DA39D',
      fontWeight: '600',
    },
  },

  '.footer': {
    height: '5rem',
    mt: 'auto',
    background: 'white',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
    borderRadius: '1.5rem',

    input: {
      all: 'unset',
      width: '100%',
      height: '3rem',
      padding: '1rem 2rem',
      fontSize: '1.5rem',
    },
    button: {
      all: 'unset',
      paddingRight: '1.5rem',
      cursor: 'pointer',
    },
  },
})

export const StyledButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',
  borderRadius: '1rem',
  background: 'White',
  p: '1rem 1.5rem',
  fontSize: '1.3rem',
  backgroundColor: '#EBEBEB',
  color: '#747575',
  width: 'max-content',
  transition: 'opacity .3s',
  border: '1px solid #ddd',

  display: 'flex',
  align: 'center',
  justifyContent: 'center',
  gap: '1rem',

  '&:hover': {
    opacity: 0.7,
  },

  variants: {
    appearance: {
      white: {
        color: 'black',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
      },
    },
  },
})

export const StyledSequence = styled('div', {
  margin: 'auto',
  //   position: 'relative',
  '.sequence-bg': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  h5: {
    textAlign: 'center',
    fontWeight: '600',
    color: '#6a6a6a',
    mb: '2rem',
  },

  '.select-first': {
    minWidth: '35rem',
  },

  '.select': {
    minWidth: '35rem',
    mt: '4rem',
    position: 'relative',
    zIndex: '1',

    '&:after': {
      content: '',
      display: 'block',
      width: '20rem',
      height: '6rem',
      borderLeft: '1px solid #aaa',
      borderBottom: '1px solid #aaa',
      borderBottomLeftRadius: '2rem',
      position: 'absolute',
      top: '-100%',
      left: '-2rem',
      zIndex: '-1',
    },
  },
  '.line': {
    '&:after': {
      height: '12rem',
      position: 'absolute',
      top: '-240%',
      left: '-2rem',
    },
  },

  '.flow': {
    ml: '4rem',
  },

  '.sequence-footer': {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: '2rem',
    borderTop: '1px solid #ccc',
    background: '#ddd',
    borderRadius: '0rem 0rem 2.5rem 2.5rem',
  },
})

export const StyledUpload = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  // zIndex: '-1',
  visibility: 'hidden',

  label: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '15rem',
    height: '15rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '2rem',
    border: '1px dashed #ccc',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '2rem',
    color: '#aaa',
    fontSize: '1.4rem',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#aaa',
    },
  },

  variants: {
    isDragging: {
      true: {
        background: '#EDEEED',
        visibility: 'visible',
      },
    },
  },
})
