/* eslint-disable react/prop-types */
import type * as Stitches from '@stitches/react'
import React, { ReactNode, Ref, isValidElement } from 'react'
import { CSS, styled } from 'stitches/stitches.config'
import { cx } from 'utils/dom'
import Flex from './flex'

const StyledFlex = styled(Flex, { width: 'auto' })

type StackProps = React.ComponentProps<typeof StyledFlex> & {
  spacing?: Stitches.CSS['margin']
  isInline?: boolean
}

export const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  ({ spacing = 16, isInline, className, children, css, ...props }, ref) => {
    const childrenCount = React.Children.count(children)
    return (
      <StyledFlex
        ref={ref as Ref<HTMLDivElement>}
        direction={isInline ? 'row' : 'column'}
        className={cx(['stack', className])}
        css={css}
        {...props}
      >
        {React.Children.map(children as ReactNode, (child, index) => {
          const isLastChild = index === childrenCount - 1

          if (!child) {
            return
          }

          if (!isValidElement(child)) {
            return null
          }

          return React.cloneElement(child as React.ReactElement, {
            css: {
              ...child.props.css,
              marginBottom: !isInline && !isLastChild ? spacing : 0,
              marginRight: isInline && !isLastChild ? spacing : 0,
            } as CSS,
          })
        })}
      </StyledFlex>
    )
  }
)

Stack.displayName = 'Stack'

export default Stack
