/* eslint-disable react/prop-types */
// import icon from 'assets/svg/favicon.svg'
import React from 'react'
import { StyledLoader } from './loader.styles'

type LoaderProps = React.ComponentProps<typeof StyledLoader> & {
  type?: string
}

const Loader = React.forwardRef<HTMLDivElement, LoaderProps>(
  ({ className, size = 'md', color = '#44C67F', css, type }, ref) => {
    return (
      <>
        {type === 'gif' ? (
          // <img
          //   style={{
          //     width: '20rem',
          //   }}
          //   src={icon}
          //   alt="loading content..."
          // />
          <StyledLoader
            ref={ref}
            size={size}
            css={{
              borderColor: `${color} ${color} $border $border`,
              ...css,
            }}
            className={'loader' + (className ? ` ${className}` : '')}
          />
        ) : (
          <StyledLoader
            ref={ref}
            size={size}
            css={{
              borderColor: `${color} ${color} $border $border`,
              ...css,
            }}
            className={'loader' + (className ? ` ${className}` : '')}
          />
        )}
      </>
    )
  }
)

Loader.displayName = 'Loader'

export default Loader
